import request from '@/utils/request'


// 查询视频评价列表
export function listDiscuss(query) {
  return request({
    url: '/zb/discuss/list',
    method: 'get',
    params: query
  })
}

// 查询视频评价分页
export function pageDiscuss(query) {
  return request({
    url: '/zb/discuss/page',
    method: 'get',
    params: query
  })
}

// 查询视频评价详细
export function getDiscuss(data) {
  return request({
    url: '/zb/discuss/detail',
    method: 'get',
    params: data
  })
}

// 新增视频评价
export function addDiscuss(data) {
  return request({
    url: '/zb/discuss/add',
    method: 'post',
    data: data
  })
}

// 修改视频评价
export function updateDiscuss(data) {
  return request({
    url: '/zb/discuss/edit',
    method: 'post',
    data: data
  })
}

// 删除视频评价
export function delDiscuss(data) {
  return request({
    url: '/zb/discuss/delete',
    method: 'post',
    data: data
  })
}
