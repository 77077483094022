<template>
  <a-drawer width="50%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="videoContent" >
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.视频描述')}}<template slot="title">{{$t('短视频.视频描述')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.videoContent" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
<!--      <a-form-model-item prop="videoPic" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('短视频.视频缩略图')}}<template slot="title">{{$t('短视频.视频缩略图')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <file-upload v-model="form.videoPic" type="image"></file-upload>-->
<!--      </a-form-model-item>-->
      <a-form-item
        v-if="form.videoType == '0'"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="视频">
        <template >
          <video controls width="320" height="240">
            <source :src="form.videoUrl" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </template>
      </a-form-item>







      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="发布图片" v-if="form.videoType == '1'">
        <div class="imageDiv" v-for="item in form.videoUrl.split(',')">
          <div class="imageDivBorder">
            <img :src="item" @click="handleRecordPreview(item)">
          </div>
        </div>
      </a-form-item>


<!--      <a-form-model-item prop="playNum" v-if="formType === 1">-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('短视频.播放次数')}}<template slot="title">{{$t('短视频.播放次数')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.playNum"  :placeholder="$t('通用.输入.请输入')+$t('短视频.播放次数')" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item prop="likeNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.点赞次数')}}<template slot="title">{{$t('短视频.点赞次数')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.likeNum"  :placeholder="$t('通用.输入.请输入')+$t('短视频.点赞次数')" />
      </a-form-model-item>
      <a-form-model-item prop="videoState" v-if="formType === 1">
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.审核状态：1:待审核 2:未通过 3:审核通过')}}<template slot="title">{{$t('短视频.审核状态：1:待审核 2:未通过 3:审核通过')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.videoState" :maxLength="1" :placeholder="$t('通用.输入.请输入')+$t('短视频.审核状态：1:待审核 2:未通过 3:审核通过')" />
      </a-form-model-item>
<!--      <a-form-model-item prop="videoPid" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('短视频.视频商品分类id')}}<template slot="title">{{$t('短视频.视频商品分类id')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.videoPid"  :placeholder="$t('通用.输入.请输入')+$t('短视频.视频商品分类id')" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item prop="videoPids" v-if="formType === 1">-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('短视频.视频商品分类ids,逗号分隔')}}<template slot="title">{{$t('短视频.视频商品分类ids,逗号分隔')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.videoPids" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('短视频.视频商品分类ids,逗号分隔')" />-->
<!--      </a-form-model-item>-->
<!--  -->


<!--      <a-form-model-item prop="atUser" >-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('短视频.@的用户id,逗号分隔')}}<template slot="title">{{$t('短视频.@的用户id,逗号分隔')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.atUser" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />-->
<!--      </a-form-model-item>-->

      <a-form-model-item prop="topicId" v-if="formType === 1">
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.话题')}}<template slot="title">{{$t('短视频.话题')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.topicId"  :placeholder="$t('通用.输入.请输入')+$t('短视频.话题')" />
      </a-form-model-item>
      <a-form-model-item prop="userId" v-if="formType === 1">
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.用户id')}}<template slot="title">{{$t('短视频.用户id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.userId"  :placeholder="$t('通用.输入.请输入')+$t('短视频.用户id')" />
      </a-form-model-item>
<!--      <a-form-model-item prop="shareNum" v-if="formType === 1">-->
<!--        <span slot="label" >-->
<!--          <a-tooltip>-->
<!--            {{$t('短视频.分享次数')}}<template slot="title">{{$t('短视频.分享次数')}}</template>-->
<!--          </a-tooltip>-->
<!--        </span>-->
<!--        <a-input v-model="form.shareNum"  :placeholder="$t('通用.输入.请输入')+$t('短视频.分享次数')" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item prop="replyNum" >
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.被评论数量')}}<template slot="title">{{$t('短视频.评论数量')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.replyNum"  :placeholder="$t('通用.输入.请输入')+$t('短视频.被评论数量')" />
      </a-form-model-item>


      <a-form-model-item prop="pubLat" v-if="formType === 1">
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.发布经度')}}<template slot="title">{{$t('短视频.发布经度')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pubLat" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('短视频.发布经度')" />
      </a-form-model-item>
      <a-form-model-item prop="pubLng" v-if="formType === 1">
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.发布纬度')}}<template slot="title">{{$t('短视频.发布纬度')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pubLng" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('短视频.发布纬度')" />
      </a-form-model-item>

      <a-form-model-item prop="address" >
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.发布地址')}}<template slot="title">{{$t('短视频.发布地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.address" :maxLength="100" :placeholder="$t('通用.输入.请输入')+$t('短视频.发布地址')" />
      </a-form-model-item>


      <a-form-model-item prop="pubCity" >
        <span slot="label" >
          <a-tooltip>
            {{$t('短视频.详细地址')}}<template slot="title">{{$t('短视频.详细地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pubCity" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('短视频.详细地址')" />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>


      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
<!--        <a-space>-->
<!--          <a-button type="primary" :loading="submitLoading" @click="submitForm">-->
<!--            {{$t('通用.按钮.保存')}}-->
<!--          </a-button>-->
<!--          <a-button type="dashed" @click="cancel">-->
<!--            {{$t('通用.按钮.取消')}}-->
<!--          </a-button>-->
<!--        </a-space>-->
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVideo, addVideo, updateVideo } from '@/api/video/video'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import Video from "@/views/video/index.vue";


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Video,
    CustomDictTag,
  },
  data () {
    return {
      //-------------------------------------------视频开始
      playerOptions: {
        // videojs options
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false,// 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        language: 'zh-CN',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4",
          src: ""
        }],
        poster: "",
        width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试' //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },

      //-------------------------------------------视频结束

      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: false,
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        videoContent: null,

        videoPic: null,

        videoUrl: null,

        videoBusinessId: null,

        playNum: null,

        likeNum: null,

        videoState: null,

        createTime: null,

        videoPid: null,

        videoPids: null,

        videoDuration: null,

        putInHours: null,

        auditOpinion: null,

        atUser: null,

        videoType: null,

        topicId: null,

        userId: null,

        shareNum: null,

        replyNum: null,

        isReward: null,

        videoBusinessType: null,

        pubLat: null,

        pubLng: null,

        limitShow: null,

        address: null,

        lastEditTime: null,

        remark: null,

        pubCity: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },




  methods: {

    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        videoContent: null,
        videoPic: null,
        videoUrl: null,
        videoBusinessId: null,
        playNum: null,
        likeNum: null,
        videoState: null,
        createTime: null,
        videoPid: null,
        videoPids: null,
        videoDuration: null,
        putInHours: null,
        auditOpinion: null,
        atUser: null,
        videoType: null,
        topicId: null,
        userId: null,
        shareNum: null,
        replyNum: null,
        isReward: null,
        videoBusinessType: null,
        pubLat: null,
        pubLng: null,
        limitShow: null,
        address: null,
        lastEditTime: null,
        remark: null,
        pubCity: null
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVideo({"id":id}).then(response => {
        this.form = response.data
        // 视频路径
        if (this.form.videoType=="0"){
          this.playerOptions.sources[0].src = this.form.videoUrl;
        }
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateVideo(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addVideo(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/** Button按钮间距 */
.ant-btn {
  margin-left: 30px;
  margin-bottom: 30px;
  float: right;
}
.imageDiv{
  float: left;width:150px;height:200px;margin-right: 10px;margin: 0 8px 8px 0;
}
.imageDiv .imageDivBorder{
  width: 100%;height: 100%;position: relative;padding: 8px;border: 1px solid #d9d9d9;border-radius: 4px;
}
.imageDiv .imageDivBorder img{
  width: 100%;
  height: 100%;
}
</style>